import { inject, Injectable } from '@angular/core';

import { ToggleableFeatures } from './feature-toggle';
import { FEATURE_TOGGLE_INJECTION_TOKEN } from './feature-toggle.token';

@Injectable({
  providedIn: 'root',
})
export class FeatureToggleService {
  private _featureToggleState = inject(FEATURE_TOGGLE_INJECTION_TOKEN);

  /**
   * Returns true if the feature is enabled.
   * @param feature The feature to check. See {@link ToggleableFeatures} for available features.
   */
  isFeatureEnabled(feature: ToggleableFeatures): boolean {
    return this._featureToggleState[feature];
  }
}
